export function calculateTierNew(matches: any) {
  let homeStake = matches[0].stakeShare;
  let drawStake = matches[1].stakeShare;
  let awayStake = matches[2].stakeShare;

  // Redistribute stake shares from compound bets
  homeStake += (matches[3].stakeShare * (matches[3].odds - 1)) / matches[3].odds; // 1X to Home
  drawStake += (matches[3].stakeShare * 1) / matches[3].odds; // 1X to Draw

  drawStake += (matches[5].stakeShare * (matches[5].odds - 1)) / matches[5].odds; // X2 to Draw
  awayStake += (matches[5].stakeShare * 1) / matches[5].odds; // X2 to Away

  homeStake += matches[4].stakeShare * 0.5; // 12 to Home
  awayStake += matches[4].stakeShare * 0.5; // 12 to Away

  const totalStake = homeStake + drawStake + awayStake;
  const homeScore = homeStake / totalStake / matches[0].odds;
  const drawScore = drawStake / totalStake / matches[1].odds;
  const awayScore = awayStake / totalStake / matches[2].odds;

  const maxScore = Math.max(homeScore, drawScore, awayScore);

  let bestOpportunity;
  if (maxScore === homeScore) bestOpportunity = 'home';
  else if (maxScore === drawScore) bestOpportunity = 'draw';
  else bestOpportunity = 'away';

  let tier;
  if (maxScore >= 0.7) tier = 'S';
  else if (maxScore >= 0.55) tier = 'A';
  else if (maxScore >= 0.4) tier = 'B';
  else if (maxScore >= 0.3) tier = 'C';
  else if (maxScore >= 0.2) tier = 'D';
  else tier = 'E';

  return { tier, bestOpportunity };
}
