import React from 'react';

const AboutSection: React.FC = () => {
  const scrollToTierList = () => {
    const tierList = document.getElementById('tier-list');
    if (tierList) {
      tierList.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="text-gray-300 p-8 flex flex-col space-y-6 sm:py-20 lg:py-52 md:px-32 sm:px-4">
      <h2 className="text-4xl font-bold font-nevermind">About Us</h2>
      <p className="text-lg">
        Welcome to <span className="text-green-300 font-bold">betier</span>, where your betting strategy gets a{' '}
        <span className="font-bold">competitive edge!</span> We specialize in providing daily snapshots of betting data
        to enhance your decision-making.
      </p>
      <div className="flex flex-col md:flex-row justify-between text-base py-16">
        <div className="md:w-1/2">
          <h3 className="text-xl text-green-500 font-bold font-nevermind">Why Choose Us?</h3>
          <p>
            By analyzing betting data once per day, we provide a <span className="font-bold">snapshot view</span> that's
            critical in identifying the most valuable betting opportunities. This non-real-time approach ensures you
            have timely information to plan your bets effectively.
          </p>
        </div>
        <div className="md:w-1/2 mt-4 md:mt-0">
          <h3 className="text-xl text-green-500 font-bold font-nevermind">
            Explore Our Tier List – No Sign Up Required!
          </h3>
          <p>
            Interested in where the smart money is going without the hassle of signing up? Our tier list is{' '}
            <span className="font-bold">free, accessible, and no login required.</span> Get insights into top betting
            opportunities at your convenience.
          </p>
        </div>
      </div>
      <button
        onClick={scrollToTierList}
        className="max-w-[450px] mt-4 m-auto bg-green-600 hover:bg-green-700 text-white font-bold font-nevermind py-2 px-4 rounded transition duration-300 ease-in-out items-center"
      >
        Uncover Top Betting Opportunities
      </button>
      <p className="text-lg pt-16">
        Discover how our strategic snapshot insights can boost your betting success. From{' '}
        <span className="font-bold">'S'</span> (superb), indicating the highest confidence in betting opportunities, to{' '}
        <span className="font-bold">'E'</span> (evaluate more), each tier is designed to enhance your betting confidence
        and success.
        <div className="pt-12 text-lg font-bol italic">
          Please note: our advice is not financial guidance and should only be used to help you make informed betting
          decisions.
        </div>
      </p>
    </div>
  );
};

export default AboutSection;
