import React, { useEffect, useState } from 'react';
import Card from '../Card/Card';
import { mapCompetitionName } from '../../utils/mapCompetitionName';
import { formatToCET } from '../../utils/formatToCET';
import { calculateTierNew } from '../../utils/calculateTier_new';

const MatchTier: React.FC = () => {
  const [cardData, setCardData] = useState<any[]>([]);

  useEffect(() => {
    fetch('https://betier-be-aacdc8de5e4a.herokuapp.com/json')
      // fetch('http://localhost:3001/json')
      .then((response) => response.json())
      .then((data) => {
        const newData = data.map((item: any) => {
          const { selections } = item.bets;
          const tierInfo = calculateTierNew(selections);

          // Assuming the selections are always in the correct order
          const homeTeam = selections[0];
          const draw = selections[1];
          const awayTeam = selections[2];
          const x1 = selections[3]; // For 1X bet
          const x12 = selections[4]; // For 12 bet
          const x2 = selections[5]; // For X2 bet

          return {
            homeTeam: homeTeam.selectionName,
            awayTeam: awayTeam.selectionName,
            odds: {
              home: homeTeam.odds,
              draw: draw.odds,
              away: awayTeam.odds,
              x1: x1.odds,
              x12: x12.odds,
              x2: x2.odds,
            },
            volume: {
              home: homeTeam.stakeShare,
              draw: draw.stakeShare,
              away: awayTeam.stakeShare,
            },
            competitionName: mapCompetitionName(item.leagueName),
            date: formatToCET(item.matchDateTime),
            tier: tierInfo.tier,
            opportunity: tierInfo.bestOpportunity,
            compoundBets: {
              x1: { volume: x1.stakeShare, odds: x1.odds },
              x12: { volume: x12.stakeShare, odds: x12.odds },
              x2: { volume: x2.stakeShare, odds: x2.odds },
            },
          };
        });

        setCardData(newData);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const tierColors = [
    'text-yellow-400',
    'text-green-500',
    'text-green-300',
    'text-blue-400',
    'text-red-500',
    'text-gray-400',
  ];
  return (
    <div className="flex flex-col w-full items-start p-8 md:px-32 sm:px-4 xs:px-4" id="tier-list">
      {['S', 'A', 'B', 'C', 'D', 'E'].map((tier, index) => (
        <div key={tier} className="w-full">
          <h2
            className={`flex text-2xl font-nevermind font-bold top-0 items-start sticky-heading ${tierColors[index]}`}
          >
            {tier} tier
          </h2>
          <div className="flex flex-row gap-5 flex-wrap py-12">
            {cardData
              .filter((item) => item.tier === tier)
              .map((item, index) => (
                <Card
                  key={index}
                  totalVolume={item.volume.home + item.volume.draw + item.volume.away}
                  homeTeam={item.homeTeam}
                  awayTeam={item.awayTeam}
                  homeTeamVolume={item.volume.home}
                  drawVolume={item.volume.draw}
                  awayTeamVolume={item.volume.away}
                  tier={item.tier}
                  opportunity={item.opportunity}
                  competitionName={item.competitionName}
                  date={item.date ? item.date : 'Missing date'}
                  homeTeamOdds={item.odds.home}
                  drawOdds={item.odds.draw}
                  awayTeamOdds={item.odds.away}
                  compoundBets={item.compoundBets}
                />
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MatchTier;
