export function formatToCET(isoString: any): string {
  const correctedDateString = isoString.replace(/-(\d{2})$/, ':$1');
  const date = new Date(correctedDateString);

  if (isNaN(date.getTime())) {
    throw new Error('Invalid time value');
  }

  const options: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Europe/Paris', // Ensuring the timezone is explicitly set
    hour12: false,
  };

  const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
  return `${formattedDate} CET`;
}
