import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="text-gray-300 p-8 flex flex-col space-y-6 sm:py-20 lg:py-52 md:px-32 sm:px-4">
      <h1 className="text-2xl font-bold font-nevermind text-center mb-4">Privacy Policy</h1>
      <p className="mb-4">
        Effective Date: <strong>4/25/2024</strong>
      </p>
      <p className="mb-4">
        Welcome to Betier ("us", "we", or "our"). Our website, accessible from https://betier.io, is dedicated to
        providing tier lists ranging from S to E for bets based on the volume and current odds. This page informs you of
        our policies regarding the collection, use, and disclosure of personal data when you use our Service and the
        choices you have associated with that data.
      </p>
      <h2 className="text-xl font-semibold font-nevermind">1. Information Collection and Use</h2>
      <p className="mb-4">
        We do not require registration, and therefore, we do not collect personally identifiable information. However,
        we may collect data that your browser sends whenever you visit our site ("Log Data"). This Log Data may include
        information such as your computer’s Internet Protocol (IP) address, browser type, browser version, the pages of
        our website that you visit, the time and date of your visit, the time spent on those pages, and other diagnostic
        data.
      </p>
      <h2 className="text-xl font-semibold font-nevermind">2. Use of Data</h2>
      <p className="mb-4">
        The data we collect is used in various ways: To provide and maintain our Service; To gather analysis or valuable
        information so that we can improve our Service; To monitor the usage of our Service; To detect, prevent, and
        address technical issues.
      </p>
      <h2 className="text-xl font-semibold font-nevermind">3. Cookies</h2>
      <p className="mb-4">
        Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent
        to your browser from a website and stored on your device. You have the option to either accept or refuse these
        cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be
        able to use some portions of our Service.
      </p>
      <h2 className="text-xl font-semibold font-nevermind">4. Service Providers</h2>
      <p className="mb-4">
        We may employ third-party companies and individuals to facilitate our Service ("Service Providers"), to provide
        the Service on our behalf, or to assist us in analyzing how our Service is used. These third parties have access
        to your Log Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any
        other purpose.
      </p>
      <h2 className="text-xl font-semibold font-nevermind">5. Security</h2>
      <p className="mb-4">
        The security of your data is important to us, but remember that no method of transmission over the Internet or
        method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect
        your Log Data, we cannot guarantee its absolute security.
      </p>
      <h2 className="text-xl font-semibold font-nevermind">6. Links to Other Sites</h2>
      <p className="mb-4">
        Our Service may contain links to other sites that are not operated by us. If you click on a third-party link,
        you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every
        site you visit.
      </p>
      <h2 className="text-xl font-semibold font-nevermind">7. Changes to This Privacy Policy</h2>
      <p className="mb-4">
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy
        Policy on this page. We advise you to review this Privacy Policy periodically for any changes. Changes to this
        Privacy Policy are effective when they are posted on this page.
      </p>
      <h2 className="text-xl font-semibold font-nevermind">Contact Us</h2>
      <p className="mb-4">
        If you have any questions about this Privacy Policy, please contact us. More contact details will be provided
        soon.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
