export function mapCompetitionName(name: string): string {
  const nameMapping: { [key: string]: string } = {
    'Liga majstrov': 'Champions League',
    'Európska liga': 'Europa League',
    'Slovenský pohár': 'Slovak Cup',
    'Portugalský pohár': 'Portuguese Cup',
    'Európska konferenčná liga': 'UEFA Conference League',
    '1. slovenská liga': 'Slovak First League',
    '1. francúzska liga': 'French Ligue 1',
    '1. španielska liga': 'Spanish La Liga',
    '1. talianska liga': 'Italian Serie A',
    '1. anglická liga': 'English Premier League',
    '1. nemecká liga': 'German Bundesliga',
    '1. rakúska liga': 'Austrian Bundesliga',
    '1. belgická liga': 'Belgian Pro League',
    '1. portugalská liga': 'Portuguese Primeira Liga',
    '1. holandská liga': 'Dutch Eredivisie',
    '1. švajčiarska liga': 'Swiss Super League',
    '1. grécka liga': 'Greek Super League',
    '1. turecká liga': 'Turkish Süper Lig',
    '1. dánska liga': 'Danish Superliga',
    '1. norská liga': 'Norwegian Eliteserien',
    '1. švédska liga': 'Swedish Allsvenskan',
    '1. fínska liga': 'Finnish Veikkausliiga',
    '1. írska liga': 'Irish Premier Division',
    '1. škótska liga': 'Scottish Premiership',
    '1. česká liga': 'Czech First League',
    '1. poľská liga': 'Polish Ekstraklasa',
    '1. chorvátska liga': 'Croatian First Football League',
    '1. srbská liga': 'Serbian SuperLiga',
    '1. rumunská liga': 'Romanian Liga I',
    '1. bulharská liga': 'Bulgarian First League',
    '1. maďarská liga': 'Hungarian First League',
    '1. slovinská liga': 'Slovenian PrvaLiga',
    '1. lotyšská liga': 'Latvian Higher League',
    '1. estónska liga': 'Estonian Meistriliiga',
    '1. litovská liga': 'A Lyga',
    '1. islandská liga': 'Icelandic Premier League',
    '1. cypruská liga': 'Cypriot First Division',
    '1. maltská liga': 'Maltese Premier League',
    '1. luxemburská liga': 'Luxembourg National Division',
    'Anglický FA Cup': 'English FA Cup',
    'Anglický ligový pohár': 'English EFL Cup',
    'Španielsky pohár': 'Spanish Copa del Rey',
    'Taliansky pohár': 'Coppa Italia',
    'Francúzsky pohár': 'Coupe de France',
    'Nemecký pohár': 'DFB-Pokal',
    'Ruský pohár': 'Russian Cup',
    'Český pohár': 'Czech Cup',
    'Poľský pohár': 'Polish Cup',
    'Chorvátsky pohár': 'Croatian Cup',
    'Srbský pohár': 'Serbian Cup',
    'Rumunský pohár': 'Romanian Cup',
    'Bulharský pohár': 'Bulgarian Cup',
    'Maďarský pohár': 'Hungarian Cup',
    'Slovinský pohár': 'Slovenian Cup',
    'Estónsky pohár': 'Estonian Cup',
    'Litovský pohár': 'Lithuanian Cup',
    'Islandský pohár': 'Icelandic Cup',
    SpanishLaLiga: 'Spanish La Liga',
    SpanishLaLiga2: 'Spanish Segunda Division',
    GermanBundesliga: 'German Bundesliga',
    GermanBundesliga2: 'German 2. Bundesliga',
    ItalianSerieA: 'Italian Serie A',
    ItalianSerieB: 'Italian Serie B',
    CopaItalia: 'Coppa Italia',
    EnglishFA: 'English FA Cup',
    EnglishEFLCup: 'English EFL Cup',
    CopaDelRey: 'Spanish Copa del Rey',
    DFBPokal: 'DFB-Pokal',
    EnglishPremierLeague: 'English Premier League',
    EnglishChampionship: 'English Championship',
    EnglishLeagueOne: 'English League One',
    EnglishLeagueTwo: 'English League Two',
    FrenchLigue1: 'French Ligue 1',
    FrenchLigue2: 'French Ligue 2',
    DutchEredivisie: 'Dutch Eredivisie',
    DutchEersteDivisie: 'Dutch Eerste Divisie',
    PortuguesePrimeiraLiga: 'Portuguese Primeira Liga',
    PortugueseLigaPro: 'Portuguese LigaPro',
    BelgianProLeague: 'Belgian Pro League',
    GreekSuperLeague: 'Greek Super League',
    TurkishSüperLig: 'Turkish Süper Lig',
    SwissSuperLeague: 'Swiss Super League',
    DanishSuperliga: 'Danish Superliga',
    SwedishAllsvenskan: 'Swedish Allsvenskan',
    NorwegianEliteserien: 'Norwegian Eliteserien',
    FinnishVeikkausliiga: 'Finnish Veikkausliiga',
    IrishPremierDivision: 'Irish Premier Division',
    ScottishPremiership: 'Scottish Premiership',
    CzechFirstLeague: 'Czech First League',
    PolishEkstraklasa: 'Polish Ekstraklasa',
    CroatianFirstFootballLeague: 'Croatian First Football League',
    SerbianSuperLiga: 'Serbian SuperLiga',
    RomanianLigaI: 'Romanian Liga I',
    BulgarianFirstLeague: 'Bulgarian First League',
    HungarianFirstLeague: 'Hungarian First League',
    SlovenianPrvaLiga: 'Slovenian PrvaLiga',
    LatvianHigherLeague: 'Latvian Higher League',
    EstonianMeistriliiga: 'Estonian Meistriliiga',
    ALyga: 'A Lyga',
    IcelandicPremierLeague: 'Icelandic Premier League',
    CypriotFirstDivision: 'Cyta Championship',
    MaltesePremierLeague: 'Maltese Premier League',
    LuxembourgNationalDivision: 'Luxembourg National Division',
    UEFAChampionsLeague: 'Champions League',
    UEFAEuropaLeague: 'Europa League',
    UEFAConferenceLeague: 'UEFA Conference League',
    UEFASuperCup: 'UEFA Super Cup',
    UEFAEuro: 'UEFA Euro',
    UEFAEuroQualifiers: 'UEFA Euro Qualifiers',
    UEFANationsLeague: 'UEFA Nations League',
    CopaAmerica: 'Copa America',
    CopaLibertadores: 'Copa Libertadores',
    CopaSudamericana: 'Copa Sudamericana',
    FIFAWorldCup: 'FIFA World Cup',
    SlovakFirstLeague: 'Slovak First League',
    SlovakCup: 'Slovak Cup',
    SlovakSuperCup: 'Slovak Super Cup',
    SlovakSecondLeague: 'Slovak Second League',
    PolishCup: 'Polish Cup',
  };

  // Return the mapped name if found, otherwise return the original name
  return nameMapping[name] || name;
}
