import React from 'react';

interface Props {
  name: string;
  odds: number;
  volume: string;
}

const Tooltip: React.FC<Props> = (props) => {
  return (
    <div className="flex flex-col items-center group absolute">
      <div className="bg-gray-800 grid gap-4 items-start z-10 text-xl leading-none text-white whitespace-no-wrap shadow-lg rounded-md p-5">
        <span className="uppercase font-extrabold"> {props.name}</span>
        <div>
          <span className="uppercase">Number of bets:</span> {props.volume}%
        </div>
        <div>
          <span className="uppercase">Odds:</span> {props.odds}
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
