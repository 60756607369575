import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import MatchTier from '../components/MatchTier/MatchTier';
import AboutSection from '../pages/About/AboutSection';
import '../assets/fonts.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivacyPolicy from '../pages/Privacy/PrivacyPolicy';

function App() {
  const [offset, setOffset] = useState(0);
  const parallaxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onScroll = () => {
      const newOffset = window.pageYOffset;
      setOffset(newOffset);
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const translateY = () => {
    if (parallaxRef.current) {
      const elementHeight = parallaxRef.current.clientHeight;
      const windowHeight = window.innerHeight;
      const docHeight = document.documentElement.scrollHeight;
      const totalScrollHeight = docHeight - windowHeight;
      const scrollPercentage = offset / totalScrollHeight;
      const calculatedY = Math.min(windowHeight - elementHeight, scrollPercentage * (windowHeight - elementHeight));
      return calculatedY;
    }
    return 0;
  };

  return (
    <div className="App bg-slate-900">
      <div
        ref={parallaxRef}
        className="parallax text-7xl md:text-9xl font-bold font-nevermind pt-12 text-gray-300"
        style={{
          transform: `translateY(${translateY()}px)`,
          filter: `blur(${Math.min(10, offset * 0.2)}px)`,
        }}
      >
        be<span className="neon-text">t</span>ier
      </div>

      <div className="content">
        <Router>
          <Routes>
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route
              path="/"
              element={
                <>
                  <AboutSection />
                  <MatchTier />
                </>
              }
            ></Route>
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
