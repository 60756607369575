import React, { useState } from 'react';
import Tooltip from '../Tooltip/Tooltip';

interface Props {
  totalVolume: number;
  homeTeam: string;
  awayTeam: string;
  homeTeamVolume: number;
  awayTeamVolume: number;
  drawVolume: number;
  tier?: string;
  opportunity?: string;
  competitionName?: string;
  date: string;
  homeTeamOdds: number;
  drawOdds: number;
  awayTeamOdds: number;
  compoundBets: {
    x1: { volume: number; odds: number };
    x12: { volume: number; odds: number };
    x2: { volume: number; odds: number };
  };
}

const Card: React.FC<Props> = (match) => {
  const [activeTooltip, setActiveTooltip] = useState<string | null>(null);

  const {
    homeTeamOdds,
    awayTeamOdds,
    drawOdds,
    totalVolume,
    homeTeam,
    awayTeam,
    homeTeamVolume,
    awayTeamVolume,
    drawVolume,
    opportunity,
    compoundBets,
  } = match;

  const totalCompoundBetsVolume = compoundBets.x1.volume + compoundBets.x12.volume + compoundBets.x2.volume;

  return (
    <>
      <div className="bg-black p-4 lg:w-3/12 md:12/12 min-w-[300px] rounded-lg shadow-lg text-gray-300">
        <div className="text-center mb-4">
          <div className="text-lg">{match.date}</div>
        </div>
        <div className="flex justify-between items-center mb-4 h-12">
          <div className="flex items-center w-3/12">
            {/* <img className="h-12 w-12 mr-3" src="logo_team1.png" alt="Team 1 Logo" /> */}
            <span className="font-bold text-xl text-wrap">{homeTeam}</span>
          </div>
          <div className="text-2xl w-3/12">-</div>
          <div className="flex items-center">
            {/* <img className="h-12 w-12 mr-3" src="logo_team2.png" alt="Team 2 Logo" /> */}
            <span className="font-bold text-xl">{awayTeam}</span>
          </div>
        </div>
        <div className="text-center mb-4">
          <div className="text-lg uppercase mb-2">Bet Predictions:</div>
          <div className="flex justify-between text-xs items-center">
            <div className=" px-1" style={{ width: `${(homeTeamVolume / totalVolume) * 100}%`, minWidth: '24px' }}>
              <div
                onMouseEnter={() => setActiveTooltip('home')}
                onMouseLeave={() => setActiveTooltip(null)}
                className="bg-green-500 rounded-full"
                style={{ height: opportunity === 'home' ? `2rem` : `1.5rem` }}
              ></div>
              {activeTooltip === 'home' && (
                <Tooltip name={homeTeam} odds={homeTeamOdds} volume={homeTeamVolume.toFixed(2)} />
              )}
            </div>
            <div className=" px-1" style={{ width: `${(drawVolume / totalVolume) * 100}%`, minWidth: '24px' }}>
              <div
                onMouseEnter={() => setActiveTooltip('draw')}
                onMouseLeave={() => setActiveTooltip(null)}
                className="bg-yellow-500 rounded-full"
                style={{ height: opportunity === 'draw' ? `2rem` : `1.5rem` }}
              ></div>
              {activeTooltip === 'draw' && <Tooltip name={'Draw'} odds={drawOdds} volume={drawVolume.toFixed(2)} />}
            </div>
            <div className=" px-1" style={{ width: `${(awayTeamVolume / totalVolume) * 100}%`, minWidth: '24px' }}>
              <div
                onMouseEnter={() => setActiveTooltip('away')}
                onMouseLeave={() => setActiveTooltip(null)}
                className="bg-red-500 rounded-full"
                style={{ height: opportunity === 'away' ? `2rem` : `1.5rem` }}
              ></div>
              {activeTooltip === 'away' && (
                <Tooltip name={awayTeam} odds={awayTeamOdds} volume={awayTeamVolume.toFixed(2)} />
              )}
            </div>
          </div>

          {/* Compound Bets */}
          <div className="text-center py-2">
            <div className="text-lg uppercase">Double chance:</div>
          </div>
          <div className="flex justify-between text-xs items-center">
            <div
              className=" px-1"
              style={{ width: `${(compoundBets.x1.volume / totalCompoundBetsVolume) * 100}%`, minWidth: '24px' }}
            >
              <div
                onMouseEnter={() => setActiveTooltip('homeDraw')}
                onMouseLeave={() => setActiveTooltip(null)}
                className="bg-cyan-500 rounded-full"
                style={{ height: '1rem' }}
              ></div>
              {activeTooltip === 'homeDraw' && (
                <Tooltip name={'1x'} odds={compoundBets.x1.odds} volume={compoundBets.x1.volume.toFixed(2)} />
              )}
            </div>
            <div
              className=" px-1"
              style={{ width: `${(compoundBets.x12.volume / totalCompoundBetsVolume) * 100}%`, minWidth: '24px' }}
            >
              <div
                onMouseEnter={() => setActiveTooltip('noDraw')}
                onMouseLeave={() => setActiveTooltip(null)}
                className="bg-violet-500 rounded-full"
                style={{ height: '1rem' }}
              ></div>
              {activeTooltip === 'noDraw' && (
                <Tooltip name={'12'} odds={compoundBets.x12.odds} volume={compoundBets.x12.odds.toFixed(2)} />
              )}
            </div>
            <div
              className=" px-1"
              style={{ width: `${(compoundBets.x2.volume / totalCompoundBetsVolume) * 100}%`, minWidth: '24px' }}
            >
              <div
                onMouseEnter={() => setActiveTooltip('awayDraw')}
                onMouseLeave={() => setActiveTooltip(null)}
                className="bg-lime-500 rounded-full"
                style={{ height: '1rem' }}
              ></div>
              {activeTooltip === 'awayDraw' && (
                <Tooltip name={'X2'} odds={compoundBets.x2.odds} volume={compoundBets.x2.volume.toFixed(2)} />
              )}
            </div>
          </div>
        </div>
        <div className="text-center">
          <div className="text-lg">{match.competitionName}</div>
        </div>
      </div>
    </>
  );
};

export default Card;
